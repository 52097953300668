@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    scroll-behavior: smooth;
}
div {
    word-break: break-word; /* Allows breaking of long words */
    overflow-wrap: break-word; /* Ensures the text will break inside the container */
    white-space: normal; /* Allows the text to wrap to the next line */
}
/* Define the colors in the root scope */
:root {
    --primary-color: #8d8f84; /* primary color */
    --secondary-color: #494543; /* Darker than primary */
    --background-color: #1e1e1d; /* dark background */
    --text-color: white;
}
:root.light-theme {
    --primary-color: #8d8f84; /* primary color */
    --secondary-color: #e0e0df; /* Darker than primary */
    --background-color: white; /* dark background */
    --text-color: #1e1e1d;
}

.button {
    background-color: var(--secondary-color);
    transition: background-color 0.3s ease;
    color:var(--text-color);
}
.button:hover {
    background-color: var(--primary-color);
}

.social {
    transition: fill 0.3s ease, stroke 0.3s ease;
}

body {
    background-color: var(--background-color);
    color: var(--text-color);
    font-family: '', sans-serif;
}
.flex {
    display: flex;
}
.outerDiv {
    width: 100%;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
}
.section {
    width: 85%;
    min-height: 100vh;
}

.text-input {
    border: none; /* Remove default input border */
    background-color: transparent; /* Make background transparent */
    font-family: inherit; /* Use the same font as the surrounding text */
    font-weight: bold;
    color: inherit; /* Use the same text color as the surrounding text */
    padding: 0; /* Remove padding */
    outline: none; /* Remove focus outline */
    cursor: text; /* Set cursor to text when hovering over */
    text-align: center;
}

.project-box {
    transition-padding: all;
    transition-duration: 0.15s;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
}
